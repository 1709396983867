<template>
  <v-card flat>
    <v-card-title>
      <div>
        <div class="headline">Payouts</div>
        <span class="subtitle-1 light-grey"></span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-btn
        color="primary"
        class="mb-5"
        :block="$vuetify.breakpoint.xsOnly"
        :disabled="! canPayoutBalance"
        @click="showConfirmPayoutDialog = true"
      >
        Payout
      </v-btn>

      <confirm-dialog
        v-model="showConfirmPayoutDialog"
        title="Bekræft udbetaling"
        :loading="payoutLoading"
        @confirm="createPayout()"
      ></confirm-dialog>

      <div class="title">Payout accounts</div>
      <payout-accounts-data-table
         :items="value.payout_accounts"
         class="mb-5"
      ></payout-accounts-data-table>

      <div class="title">Payouts for active account</div>
        <payouts-data-table
          v-if="activePayoutAccount"
          :items="payouts"
          :loading="loading"
          :payout-account="activePayoutAccount"
      ></payouts-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import PayoutsDataTable from "../../../data-tables/PayoutsDataTable";
import ConfirmDialog from "../../../dialogs/ConfirmDialog";
import { mapActions } from "vuex";
import PayoutAccountsDataTable from "../../../data-tables/PayoutAccountsDataTable";

export default {
  components: { PayoutAccountsDataTable, ConfirmDialog, PayoutsDataTable },
  filters: { local_numbers, utc_to_local_datetime },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    payoutAccount: null,
    payoutAccounts: [],
    payouts: [],
    loading: true,
    showConfirmPayoutDialog: false,
    payoutLoading: false,
    lastPayoutId: null
  }),
  computed: {
    activePayoutAccount() {
      let payoutAccount = this.value.payout_accounts.filter(
        item => item.active === true
      );

      if(payoutAccount.length > 0) {
        return payoutAccount[0];
      }

      return null;
    },
    canPayoutBalance() {
      return this.activePayoutAccount
       && this.activePayoutAccount.balance > 100
       && this.activePayoutAccount.payouts_enabled_at;
    }
  },
  methods: {
    ...mapActions("influencer", [
      "getPayouts",
      "getPayoutAccounts",
      "createConnectAccountPayout"
    ]),
    getAllPayouts() {
      this.loading = true;
      const data = {
        limit: 100,
        payout_account_id: this.activePayoutAccount.uuid,
        starting_after: this.lastPayoutId
      }
      this.getPayouts(data).then(response => {
        this.loading = false;
        this.payouts = response.data;
        this.lastPayoutId = response.last_payout_id;
      });
    },
    createPayout() {
      this.payoutLoading = true;
      this.createConnectAccountPayout({
        payout_account_id: this.activePayoutAccount.uuid
      }).then(
        () => {
          this.payoutLoading = false;
          this.value.stripe_balance_available = 0;
          this.showConfirmPayoutDialog = false;
          this.getPayouts();
          this.setSnackSuccess("Success");
        },
        () => {
          this.payoutLoading = false;
          this.setSnackError('Something went wrong')
        }
      );
    }
  },
  mounted() {
    this.getAllPayouts();
  }
};
</script>
