<template>
  <v-dialog v-model="showing" max-width="550px">
    <v-card>
      <v-card-title>
        Create transaction
      </v-card-title>
      <v-card-text>
        <v-select
           v-model="transactionType"
           :items="transactionTypes"
           label="Type"
           outlined
           dense
        ></v-select>
        <v-text-field
           v-model="amount"
           label="Amount"
           outlined
           type="number"
        ></v-text-field>
        <v-textarea
           v-model="internalNote"
           outlined
           label="Internal note"
           placeholder="Only visible to employees"
           persistent-placeholder
           counter="1000"
           maxlength="1000"
        ></v-textarea>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="showing = false">
          Close
        </v-btn>
        <v-btn color="primary" :loading="loading" :disabled="amount <= 0 || transactionType === null" @click="_createTransaction()">
          Create
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  props: {
    value: {
      required: true
    },
    influencer: {
      required: true
    }
  },
  data: () => ({
    transactionTypes: [{text: 'Referral', value: 6}],
    transactionType: null,
    amount: 0,
    internalNote: '',
    loading: false
  }),
  computed: {
    showing: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
  },
  methods: {
    ...mapActions('core', ['createTransaction']),
    _createTransaction() {
      this.loading = true;

      let params = {
        amount: Number.parseFloat(this.amount),
        type: this.transactionType,
        influencer_id: this.influencer.uuid,
        internal_note: this.internalNote
      };

      this.createTransaction(params).then(() => {
          this.showing = false;
          this.setSnackSuccess('Success');
        }).catch(err => {
          console.error(err);
          this.setSnackError("Error");
        });
    }
  }
};
</script>
