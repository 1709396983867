<template>
  <v-tabs
    v-if="influencer"
    v-model="selectedTab"
    grow
    icons-and-text
    show-arrows
    background-color="grey lighten-4"
    class="elevation-1"
  >
    <v-tab v-for="tab in tabs" :key="tab.name" class="accent--text">
      {{ tab.label }}
      <v-icon color="accent">{{ tab.icon }}</v-icon>
    </v-tab>
    <v-tabs-slider color="accent"></v-tabs-slider>

    <v-tabs-items v-model="selectedTab" touchless>
      <v-tab-item v-for="tab in tabs" :key="tab.name">
        <v-card v-if="tab.name === 'info'" flat>
          <v-card-text>
            <h3 class="headline">Kategorier</h3>

            <v-layout row wrap>
              <v-flex
                v-for="category in allCategories"
                :key="category.id"
                xs12
                sm12
                md4
              >
                <v-chip outlined style="width:100%;" disabled color="primary">
                  <v-avatar>
                    <v-icon class="ml-2">{{ category.icon }}</v-icon>
                  </v-avatar>

                  {{ category.display_name }}
                </v-chip>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
        <v-card v-else-if="tab.name === 'channels'" flat>
          <v-card-title>
            <div>
              <div class="headline">Kanaler</div>
              <span class="subtitle-1 light-grey"
                >Kanaler der er tilsluttet Make Influence</span
              >
            </div>
          </v-card-title>
          <v-card-text>
            <influencer-channel-data-table
              :items="influencer.channels"
            ></influencer-channel-data-table>
          </v-card-text>
        </v-card>
        <campaigns-tab
          v-else-if="tab.name === 'campaigns'"
          v-model="influencer"
        ></campaigns-tab>
        <stats-tab
          v-else-if="tab.name === 'stats'"
          v-model="influencer"
        ></stats-tab>
        <sales-tab
          v-else-if="tab.name === 'sales'"
          v-model="influencer"
        ></sales-tab>
        <payouts-tab
          v-else-if="tab.name === 'invoices'"
          v-model="influencer"
        ></payouts-tab>

        <v-card v-else flat>
          <v-card-text>
            <v-alert :value="true" type="warning">Under construction</v-alert>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-tabs>
</template>

<script>
import InfluencerChannelDataTable from "../../data-tables/InfluencerChannelDataTable";
import StatsTab from "./StatsTab";
import SalesTab from "./SalesTab";
import CampaignsTab from "./CampaignsTab";
import PayoutsTab from "./PayoutsTab";
export default {
  components: {
    PayoutsTab,
    CampaignsTab,
    SalesTab,
    StatsTab,
    InfluencerChannelDataTable
  },
  props: {
    influencer: {
      default: null
    }
  },
  data: () => ({
    selectedTab: null,
    tabs: [
      {
        name: "info",
        label: "Info",
        icon: "fal fa-user"
      },
      {
        name: "channels",
        label: "Medier",
        icon: "fab fa-instagram"
      },
      {
        name: "campaigns",
        label: "Kampagner",
        icon: "fal fa-bullhorn"
      },
      {
        name: "stats",
        label: "Præstation",
        icon: "fal fa-chart-area"
      },
      {
        name: "sales",
        label: "Salg",
        icon: "fal fa-shopping-basket"
      },
      {
        name: "invoices",
        label: "Udbetalinger",
        icon: "fal fa-receipt"
      }
    ]
  }),
  computed: {
    allCategories() {
      if (!this.influencer) {
        return [];
      }

      let categories = [];

      this.influencer.channels.forEach(channel => {
        channel.categories.forEach(category => {
          if (categories.filter(item => item.id === category.id).length === 0) {
            categories.push(category);
          }
        });
      });

      return categories;
    }
  },
  methods: {},
  watch: {},
  created() {}
};
</script>
