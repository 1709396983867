<template>
  <v-card>
    <v-card-title>
      <div>
        <div class="headline">Statistikker</div>
        <span class="subtitle-1 light-grey">Oversigt over nuværende måned</span>
      </div>
    </v-card-title>
    <v-card-text>
      <v-container fluid grid-list-xl class="p-0">
        <v-layout wrap>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-hand-point-up"
              :title="$options.filters.local_numbers(stats.clicks)"
              subtitle="Klik"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-shopping-basket"
              :title="$options.filters.local_numbers(stats.sales)"
              subtitle="Salg"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-percentage"
              :title="$options.filters.local_numbers(stats.conversion) + '%'"
              subtitle="Konvetering"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-fingerprint"
              :title="$options.filters.local_numbers(stats.unique_clicks)"
              subtitle="Unikke klik"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-coins"
              :title="$options.filters.local_numbers(stats.value)"
              subtitle="Omsætning"
              flat
              bordered
            ></stat-widget>
          </v-flex>
          <v-flex xs12 sm6 md6 xl4>
            <stat-widget
              icon="fal fa-money-bill-wave"
              :title="$options.filters.local_numbers(stats.commission)"
              subtitle="Kommission"
              flat
              bordered
            ></stat-widget>
          </v-flex>

          <v-flex xs12>
            <div class="subheading mt-3">Daglig præstation</div>
            <daily-stats-data-table
              :loading="loading"
              :items="dailyStats"
            ></daily-stats-data-table>
          </v-flex>
        </v-layout>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
import StatWidget from "../../../widgets/StatWidget";
import local_numbers from "@/helpers/filters/local_numbers";
import DailyStatsDataTable from "../../data-tables/DailyStatsDataTable";
import { mapActions } from "vuex";

export default {
  components: { DailyStatsDataTable, StatWidget },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    stats: {},
    dailyStats: [],
    loading: true
  }),
  computed: {},
  methods: {
    ...mapActions("core", ["getStats"])
  },
  watch: {},
  created() {
    const statsParams = {
      start_date: this.$moment()
        .startOf("month")
        .format("YYYY-MM-DD"),
      end_date: this.$moment().format("YYYY-MM-DD"),
      influencer_uuid: this.$route.params.id
    };

    this.getStats(statsParams).then(
      response => {
        this.stats = response[0];
        this.isLoading = false;
      },
      error => {
        console.log(error);
      }
    );

    this.getStats({ ...statsParams, ...{ group: ["date"] } }).then(
      response => {
        this.loading = false;
        this.dailyStats = response;
      },
      error => {
        console.log(error);
      }
    );
  }
};
</script>
