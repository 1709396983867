var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":""}},[_c('v-card-title',[_c('div',[_c('div',{staticClass:"headline"},[_vm._v("Salg")]),_c('span',{staticClass:"subtitle-1 light-grey"},[_vm._v("Oversigt over de seneste 100 salg")])])]),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.sales,"hide-default-footer":"","items-per-page":10000},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item){return _c('tr',{key:item.uuid},[_c('td',[_c('sales-status-icon',{attrs:{"sale":item}})],1),_c('td',[_vm._v(_vm._s(_vm._f("utc_to_local_datetime")(item.created_at)))]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.value)))]),_c('td',[_vm._v(_vm._s(_vm._f("local_numbers")(item.commission)))]),_c('td',[_vm._v(_vm._s(item.unique_key))]),_c('td',[(item.click)?_c('router-link',{attrs:{"title":item.click.campaign.name,"to":{
                  name: 'business',
                  params: { id: item.click.campaign.business.uuid }
                }}},[_vm._v(" "+_vm._s(item.click.campaign.business.display_name)+" ")]):(item.promotion_code)?_c('router-link',{attrs:{"to":{
                  name: 'business',
                  params: { id: item.promotion_code.business.uuid }
                }}},[_vm._v(" "+_vm._s(item.promotion_code.business.display_name)+" ")]):_vm._e()],1),_c('td',[_c('v-btn',{attrs:{"outlined":"","icon":"","color":"primary"},on:{"click":function($event){_vm.selectedSale = item}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("fal fa-eye")])],1),(_vm.selectedSale && _vm.selectedSale.uuid === item.uuid)?_c('sales-information-dialog',{on:{"update":function($event){return _vm.updateSale(item, $event)}},model:{value:(_vm.selectedSale),callback:function ($$v) {_vm.selectedSale=$$v},expression:"selectedSale"}}):_vm._e()],1)])}),0)]}}])},[_c('v-progress-linear',{attrs:{"slot":"progress","color":"primary","indeterminate":""},slot:"progress"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }