<template>
  <v-dialog v-if="value !== null" v-model="showing" max-width="550px">
    <v-card v-if="!disapproveSale">
      <v-card-title class="headline" primary-title>
        Salgs information
      </v-card-title>

      <v-card-text>
        <v-list two-line>
          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-user</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  v-if="value.click"
                  :title="value.click.campaign.name"
                  :to="{
                    name: 'business',
                    params: { id: value.click.campaign.business.uuid }
                  }"
                >
                  {{ value.click.campaign.business.display_name }}
                </router-link>
                <router-link
                  v-else-if="value.promotion_code"
                  :to="{
                    name: 'business',
                    params: { id: value.promotion_code.business.uuid }
                  }"
                >
                  {{ value.promotion_code.business.display_name }}
                </router-link>
                <router-link
                  v-else-if="value.business"
                  :to="{
                    name: 'business',
                    params: { id: value.business.uuid }
                  }"
                >
                  {{ value.business.display_name }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>Virksomhed</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>
                <router-link
                  v-if="value.click"
                  :to="{
                    name: 'influencer',
                    params: {
                      id: value.click.influencer_channel.influencer.uuid
                    }
                  }"
                >
                  {{
                    value.click.influencer_channel.influencer.user.first_name +
                      " " +
                      value.click.influencer_channel.influencer.user.last_name
                  }}
                </router-link>
                <router-link
                  v-else-if="value.influencer"
                  :to="{
                    name: 'influencer',
                    params: { id: value.influencer.uuid }
                  }"
                >
                  {{ value.influencer.user.full_name }}
                </router-link>
                <router-link
                  v-else-if="value.promotion_code"
                  :to="{
                    name: 'influencer',
                    params: { id: value.promotion_code.influencer.uuid }
                  }"
                >
                  {{
                    value.promotion_code.influencer.user.first_name +
                      " " +
                      value.promotion_code.influencer.user.last_name
                  }}
                </router-link>
              </v-list-item-title>
              <v-list-item-subtitle>Influencer</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="value.click">
            <v-list-item-action>
              <v-icon>fal fa-calendar-alt</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{
                timeDifference(value.click.created_at, value.created_at)
              }}</v-list-item-title>
              <v-list-item-subtitle>Konverteringstid</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.created_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Salgs tidspunkt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="!editingValue" @click="editValue(true)">
            <v-list-item-action>
              <v-icon>fal fa-money-bill</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ value.value }}</v-list-item-title>
              <v-list-item-subtitle>Value</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-action>
              <v-icon>fal fa-money-bill</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex">
                <v-text-field
                  class="mr-3"
                  v-model="saleValue"
                  type="number"
                  label="Value"
                  :error="!saleValue"
                ></v-text-field>
                <div class="d-flex align-center">
                  <div
                    class="mr-3 cursor-pointer"
                    @click.stop="editValue(false)"
                  >
                    <v-icon color="error" size="30">fal fa-times</v-icon>
                  </div>
                  <div
                    class="cursor-pointer"
                    @click.stop="changeSale('value', saleValue)"
                  >
                    <v-icon color="success" size="30">fal fa-check</v-icon>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>
          <!-- comission -->
          <v-list-item v-if="!editingCommission" @click="editCommission(true)">
            <v-list-item-action>
              <v-icon>fal fa-money-bill-wave</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ value.commission }}</v-list-item-title>
              <v-list-item-subtitle>Commission</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-else>
            <v-list-item-action>
              <v-icon>fal fa-money-bill-wave</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <div class="d-flex">
                <v-text-field
                  class="mr-3"
                  v-model="saleCommission"
                  label="Commission"
                  :error="!saleCommission"
                ></v-text-field>
                <div class="d-flex align-center">
                  <div
                    class="mr-3 cursor-pointer"
                    @click.stop="editCommission(false)"
                  >
                    <v-icon color="error" size="30">fal fa-times</v-icon>
                  </div>
                  <div
                    class="cursor-pointer"
                    @click.stop="changeSale('commission', saleCommission)"
                  >
                    <v-icon color="success" size="30">fal fa-check</v-icon>
                  </div>
                </div>
              </div>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.click">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.click.created_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Klik tidspunkt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="value.click || value.promotion_code">
            <v-list-item-action>
              <v-icon>fal fa-globe-europe</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ value.ip }}</v-list-item-title>
              <v-list-item-subtitle>Salgs IP</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.click">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ value.click.ip }}</v-list-item-title>
              <v-list-item-subtitle>Klik IP</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-handshake</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-if="value.click || value.promotion_code">
                {{ value.click_type ? value.click_type : "Rabat kode" }}
                </v-list-item-title>
                 <v-list-item-title v-else>
                 Manuel
                </v-list-item-title>
              <v-list-item-subtitle>Erhvervelse</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.promotion_code">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ value.promotion_code.code }}</v-list-item-title>
              <v-list-item-subtitle>Rabat kode</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item v-if="value.click">
            <v-list-item-action>
              <v-icon>fal fa-link</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <a
                  v-if="value.click.http_link"
                  :href="value.click.http_link.link"
                  target="_blank"
                  >{{ value.click.http_link.link }}</a
                >
                <v-icon v-else small color="error">fal fa-times</v-icon>
              </v-list-item-title>
              <v-list-item-subtitle>Deep link</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-id-card</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>{{ value.unique_key }}</v-list-item-title>
              <v-list-item-subtitle>Ordre nr.</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item>
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ value.uuid }}</v-list-item-title>
              <v-list-item-subtitle>Internt salgs ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.click">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ value.click.uuid }}</v-list-item-title>
              <v-list-item-subtitle>Internt klik ID</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-divider inset></v-divider>

          <v-list-item>
            <v-list-item-action>
              <v-icon>fal fa-analytics</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                <sales-status-icon :sale="value"></sales-status-icon>
              </v-list-item-title>
              <v-list-item-subtitle>Salgs status</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.disapproved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.disapproved_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Afvist tidspunkt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.disapproved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.disapproved_note
              }}</v-list-item-title>
              <v-list-item-subtitle>Afvist note</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.approved_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.approved_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle
                >Godkendelses tidspunkt</v-list-item-subtitle
              >
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.paid_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.paid_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Betalings tidspunkt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-if="value.payout_at">
            <v-list-item-action></v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{
                value.payout_at | utc_to_local_datetime
              }}</v-list-item-title>
              <v-list-item-subtitle>Udbetalings tidspunkt</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions v-if="!value.approved_at && !value.disapproved_at">
        <v-spacer></v-spacer>
        <v-btn text @click="disapproveSale = true">
          Afvis salg
        </v-btn>
      </v-card-actions>
    </v-card>

    <v-card v-if="disapproveSale">
      <v-card-title>
        <div>
          <div class="headline">Afvis salg</div>
          <span class="subtitle-1 light-grey">Noten skal udfyldes!</span>
        </div>
      </v-card-title>

      <v-card-text>
        <v-textarea
          v-model="disapproveNote"
          label="Afvisnings note"
          hint="Minimum 5 tegn. Noten er synlig for influencers!"
          counter="128"
        ></v-textarea>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="disapproveNote.length < 5"
          :loading="disapproveLoading"
          color="error"
          @click="disapprove()"
        >
          Afvis salg
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import SalesStatusIcon from "../../icons/SalesStatusIcon";
import time_difference from "@/helpers/mixins/time_difference";
import { mapActions } from "vuex";

export default {
  components: { SalesStatusIcon },
  mixins: [time_difference],
  props: {
    value: {
      required: true
    }
  },
  filters: {
    utc_to_local_datetime
  },
  data: () => ({
    disapproveSale: false,
    disapproveNote: "",
    disapproveLoading: false,
    saleValue: null,
    saleCommission: null,
    editingValue: false,
    editingCommission: false
  }),
  computed: {
    showing: {
      get() {
        return this.value !== null;
      },
      set() {
        this.disapproveSale = false;
        this.disapproveNote = "";
        this.disapproveLoading = false;

        this.$emit("input", null);
      }
    },
    canEdit() {
      if (this.value.approved_at || this.value.stripe_invoice_key) {
        return false;
      } else {
        return true;
      }
    },
    // Calculate time between click and sale
    clickConversionTime() {
      if (!this.value || !this.value.click) {
        return "";
      }

      let start = this.$moment(
        this.value.click.created_at,
        "YYYY-MM-DD HH:mm:ss"
      );
      let end = this.$moment(this.value.created_at, "YYYY-MM-DD HH:mm:ss");

      let duration = this.$moment.duration(end.diff(start));

      if (duration.asHours() >= 24) {
        return Math.round(duration.asDays()) + " days";
      } else if (duration.asMinutes() >= 120) {
        return Math.round(duration.asHours()) + " hours";
      } else if (duration.asSeconds() < 120) {
        return duration.asSeconds() + " seconds";
      }

      return Math.round(duration.asMinutes()) + " minutes";
    }
  },
  methods: {
    ...mapActions("moderator", ["updateSale"]),
    disapprove() {
      this.disapproveLoading = true;

      let params = {
        uuid: this.value.uuid,
        disapproved_note: this.disapproveNote
      };

      this.updateSale(params).then(
        response => {
          this.setSnackSuccess("Gemt");
          this.disapproveLoading = false;
          this.showing = false;
          this.$emit("update", response);
        },
        () => {
          this.setSnackError("Fejl");
          this.disapproveLoading = false;
        }
      );
    },
    editValue(value) {
      if (!this.canEdit) {
        return false;
      }
      this.editingValue = value;
      if (value) {
        this.saleValue = this.value.value;
      }
    },
    editCommission(value) {
      if (!this.canEdit) {
        return false;
      }
      this.editingCommission = value;
      if (value) {
        this.saleCommission = this.value.commission;
      }
    },
    changeSale(field, value) {
      const params = {
        [field]: Number(value),
        uuid: this.value.uuid
      };
      this.updateSale(params).then(
        sale => {
          this.setSnackSuccess("Gemt");
          this.$emit("update", sale);
          this.showing = false;
        },
        () => {
          this.disapproveLoading = false;
          this.setSnackError("Fejl");
        }
      );
    }
  }
};
</script>
