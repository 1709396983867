<template>
  <v-card flat>
    <v-card-title>
      <div>
        <div class="headline">Salg</div>
        <span class="subtitle-1 light-grey"
          >Oversigt over de seneste 100 salg</span
        >
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="sales"
        hide-default-footer
        :items-per-page="10000"
      >
        <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
        ></v-progress-linear>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.uuid">
              <td>
                <sales-status-icon :sale="item"></sales-status-icon>
              </td>
              <td>{{ item.created_at | utc_to_local_datetime }}</td>
              <td>{{ item.value | local_numbers }}</td>
              <td>{{ item.commission | local_numbers }}</td>
              <td>{{ item.unique_key }}</td>
              <td>
                <router-link
                  v-if="item.click"
                  :title="item.click.campaign.name"
                  :to="{
                    name: 'business',
                    params: { id: item.click.campaign.business.uuid }
                  }"
                >
                  {{ item.click.campaign.business.display_name }}
                </router-link>
                <router-link
                  v-else-if="item.promotion_code"
                  :to="{
                    name: 'business',
                    params: { id: item.promotion_code.business.uuid }
                  }"
                >
                  {{ item.promotion_code.business.display_name }}
                </router-link>
              </td>
              <td>
                <v-btn
                  outlined
                  icon
                  color="primary"
                  @click="selectedSale = item"
                >
                  <v-icon small>fal fa-eye</v-icon>
                </v-btn>

                <sales-information-dialog
                  v-if="selectedSale && selectedSale.uuid === item.uuid"
                  v-model="selectedSale"
                  @update="updateSale(item, $event)"
                ></sales-information-dialog>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import SalesStatusIcon from "../../../icons/SalesStatusIcon";
import SalesInformationDialog from "../../dialogs/SalesInformationDialog";
import { mapActions } from "vuex";

export default {
  components: { SalesInformationDialog, SalesStatusIcon },
  filters: { local_numbers, utc_to_local_datetime },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    headers: [
      { text: "", sortable: false },
      { text: "Salgs tidspunkt", value: "created_at" },
      { text: "Værdi", value: "value" },
      { text: "Kommission", value: "commission" },
      { text: "Ordre nr.", value: "unique_key" },
      { text: "Virksomhed", value: "click.campaign.business.company" },
      { text: "", sortable: false }
    ],
    sales: [],
    selectedSale: null
  }),
  computed: {},
  methods: {
    ...mapActions("core", ["getSales"]),
    updateSale(oldSale, newSale) {
      let index = this.sales.indexOf(oldSale);
      Object.assign(this.sales[index], newSale);
    }
  },
  watch: {},
  created() {
    const params = {
      influencer_uuid: this.value.uuid,
      page: 1,
      limit: 100
    };
    this.getSales(params).then(sales => {
      this.sales = sales.response;
    });
  }
};
</script>
