<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="activeChannels"
      :loading="loading"
      hide-default-footer
      :items-per-page="10000"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.uuid">
            <td>
              <channel-avatar
                :channel="item.channel"
                :size="22"
              ></channel-avatar>
            </td>
            <td>
              <a
                :href="'https://www.instagram.com/' + item.username"
                target="_blank"
              >
                {{ item.username }}
              </a>
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <div v-on="on">{{ item.followers | friendly_numbers }}</div>
                </template>
                <span>{{ item.followers | local_numbers }}</span>
              </v-tooltip>
            </td>
            <td>{{ item.media_count }}</td>
            <td>{{ item.channel.updated_at | utc_to_local_datetime }}</td>
            <td>
              <v-tooltip v-if="item.api_failed_at" top>
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on" color="error">fal fa-times</v-icon>
                </template>
                <span>{{ item.api_failed_at | utc_to_local_datetime }}</span>
              </v-tooltip>
              <v-icon v-else color="success">fal fa-check</v-icon>
            </td>
            <td>{{ item.active ? "Ja" : "Nej" }}</td>
            <td v-if="item.channel.approved_at">
              {{ item.channel.approved_at | utc_to_local_datetime }}
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    v-on="on"
                    color="primary"
                    outlined
                    icon
                    @click="selectedInfluencerChannel = item"
                  >
                    <v-icon small>fal fa-chart-line</v-icon>
                  </v-btn>
                </template>
                <span>Se følger data</span>
              </v-tooltip>
            </td>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <influencer-channel-data-dialog
      v-model="selectedInfluencerChannel"
    ></influencer-channel-data-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import friendly_numbers from "@/helpers/filters/friendly_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import ChannelAvatar from "../../avatars/ChannelAvatar";
import InfluencerChannelDataDialog from "../dialogs/InfluencerChannelDataDialog";

export default {
  components: { InfluencerChannelDataDialog, ChannelAvatar },
  filters: {
    local_numbers,
    friendly_numbers,
    utc_to_local_datetime
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    headers: [
      { text: "Channel", value: "channel.display_name" },
      { text: "Username", value: "username" },
      { text: "Followers", value: "followers" },
      { text: "Posts", value: "media_count" },
      { text: "Data updated", value: "api_updated_at" },
      { text: "API status", value: "api_failed_at" },
      { text: "Active", value: "active" },
      { text: "Approved at", value: "approved_at" },
      { text: "", sortable: false }
    ],
    selectedInfluencerChannel: null
  }),
  computed: {
    activeChannels() {
      return this.items.filter(item => item.active);
    }
  },
  methods: {}
};
</script>
