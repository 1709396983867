<template>
  <v-card flat>
    <v-card-title>
      <div>
        <div class="headline">Can</div>
        <span class="subtitle-1 light-grey"
          >Tilmeldte, inviterede og afvist kampagner</span
        >
      </div>
    </v-card-title>
    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="campaigns"
        :loading="loading"
        hide-default-footer
      >
        <v-progress-linear
          slot="progress"
          color="primary"
          indeterminate
        ></v-progress-linear>
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="item in items" :key="item.uuid">
              <td>
                <router-link
                  :to="{ name: 'campaign', params: { id: item.campaign.uuid } }"
                >
                  {{ item.campaign.name }}
                </router-link>
              </td>
              <td>
                <router-link
                  :to="{ name: 'business', params: { id: item.campaign.business.uuid } }"
                >
                  {{ item.campaign.business.display_name }}
                </router-link>
              </td>
              <td>
                <template
                  v-if="
                    item.cost_per_action_percent > 0 &&
                      item.cost_per_action_fixed > 0
                  "
                >
                  {{
                    item.cost_per_action_percent | local_numbers
                  }}% + DKK
                  {{
                    item.cost_per_action_fixed | local_numbers
                  }}
                </template>
                <template
                  v-else-if="item.cost_per_action_percent > 0"
                >
                  {{
                    item.cost_per_action_percent | local_numbers
                  }}%
                </template>
                <template
                  v-else-if="item.cost_per_action_fixed > 0"
                >
                  DKK
                  {{
                    item.cost_per_action_fixed | local_numbers
                  }}
                </template>
              </td>
              <td>
                <template v-if="[1].includes(item.status)">
                  Pending invitation
                </template>
                <template v-else-if="[2].includes(item.status)">
                  Pending application
                </template>
                <template v-else-if="[3,4].includes(item.status)">
                  Accepted
                </template>
                <template v-else-if="[5,6].includes(item.status)">
                  Rejected
                </template>
                <template v-else-if="[7,8].includes(item.status)">
                  Retracted
                </template>
                <template v-else-if="[9,10,11,12].includes(item.status)">
                  Ended
                </template>
              </td>
              <td>
                <campaign-status-icon
                  :value="item.campaign"
                  show-text
                ></campaign-status-icon>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
      <v-pagination
        v-model="page"
        class="my-2"
        :length="total"
        :total-visible="7"
        @input="handlePageChange"
      ></v-pagination>
    </v-card-text>
  </v-card>
</template>

<script>
import CampaignStatusIcon from "../../../icons/CampaignStatusIcon";
import local_numbers from "@/helpers/filters/local_numbers";
import { mapActions } from "vuex";
export default {
  components: { CampaignStatusIcon },
  filters: { local_numbers },
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data: () => ({
    headers: [
      { text: "Kampagne", value: "name", sortable: false },
      { text: "Virksomhed", value: "business.display_name", sortable: false },
      { text: "Betaling pr. salg", sortable: false },
      { text: "Status", sortable: false },
      { text: "Kampagne status", value: "status.order", sortable: false }
    ],
    campaigns: [],
    page: 1,
    limit: 15,
    total: null,
    loading: true
  }),
  methods: {
    ...mapActions("core/campaigns", ["loadCampaigns", "getCampaignRequestsForInfluencer"]),

    getCampaigns(page = 1) {
      this.page = page;
      this.campaigns = [];
      // const params = {
      //   page: this.page,
      //   limit: this.limit,
      //   influencer_uuid: this.value.uuid,
      //   with: ["influencers"]
      // };
      // this.loadCampaigns(params).then(campaigns => {
      //   this.loading = false;
      //   this.total = campaigns.lastPage;
      //   this.campaigns = campaigns.response;
      // });

      const params = {
        influencer_id: this.value.uuid,
        page: this.page,
        limit: this.limit,
      };

      this.loading = true;

      this.getCampaignRequestsForInfluencer(params).then(campaignRequests => {
        this.campaigns = campaignRequests.data;

        this.total = campaignRequests.lastPage;
        this.loading = false;
      });

    },
    handlePageChange(value) {
      this.getCampaigns(value);
    }
  },
  created() {
    this.getCampaigns();
  }
};
</script>
