<template>
  <div>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="100"
      :sort-by="['active']"
      :sort-desc="[true]"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
        <tr v-for="item in items" :key="item.id">
          <td>{{ item.active ? 'Yes' : 'No' }}</td>
          <td>{{ item.account_type === 1 ? 'Business' : 'Personal' }}</td>
          <td>{{ item.is_taxable ? 'Yes' : 'No' }}</td>
          <td>{{ item.tax_id }}</td>
          <td>
            {{ (item.balance / 100) | local_numbers }}
          </td>
          <td>
            {{ item.transfers_enabled_at ? 'Enabled' : '-' }}
          </td>
          <td>
            {{ item.payouts_enabled_at ? 'Enabled' : '-' }}
          </td>
          <td>
            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                   v-on="on"
                   class="mr-2"
                   outlined
                   icon
                   color="primary"
                   @click="selectedItem = item; showMarkInactiveDialog = true;"
                   :disabled="! item.active"
                >
                  <v-icon small>fal fa-times</v-icon>
                </v-btn>
              </template>
              <span>Mark inactive</span>
            </v-tooltip>

            <v-tooltip left>
              <template v-slot:activator="{ on }">
                <v-btn
                   v-on="on"
                   outlined
                   icon
                   color="primary"
                   @click="selectedItem = item; showMarkActiveDialog = true;"
                   :disabled="item.active"
                >
                  <v-icon small>fal fa-check</v-icon>
                </v-btn>
              </template>
              <span>Mark active</span>
            </v-tooltip>
          </td>
        </tr>
        </tbody>
      </template>

      <div class="text-center" slot="no-data">
        No payout accounts
      </div>
    </v-data-table>

    <v-dialog v-if="showMarkInactiveDialog" v-model="showMarkInactiveDialog" max-width="500px">
      <v-card>
        <v-card-title>
          Mark inactive
        </v-card-title>
        <v-card-text>
          <div class="mb-3">Payout account id: {{ selectedItem.uuid }}</div>

          <ol class="mb-3">
            <li>Ask the influencer to download all payout PDF's</li>
            <li>Mark as inactive</li>
            <li>Next time influencer logs in they have to create a new payout account</li>
          </ol>

          <v-text-field
             v-model="confirmText"
             outlined
             placeholder="Type Confirm to unlock button"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary">Cancel</v-btn>
          <v-btn
             text
             :disabled="confirmText !== 'Confirm'"
             :loading="actionLoading"
             @click="markInactive()"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showMarkActiveDialog" v-model="showMarkActiveDialog" max-width="500px">
      <v-card>
        <v-card-title>
          Mark active
        </v-card-title>
        <v-card-text>
          <div class="mb-3">Payout account id: {{ selectedItem.uuid }}</div>

          This will mark all other active accounts as inactive. Only 1 active payout account per influencer.

          <v-text-field
             v-model="confirmText"
             class="mt-3"
             outlined
             placeholder="Type Confirm to unlock button"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn outlined color="primary">Cancel</v-btn>
          <v-btn text :disabled="confirmText !== 'Confirm'" :loading="actionLoading" @click="markActive()">
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import {mapActions} from "vuex";

export default {
  filters: {
    local_numbers,
  },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      }
    },
    loading: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  data: () => ({
    headers: [
      {text: "Active", sortable: true, value: 'active'},
      {text: "Type", sortable: false},
      {text: "Taxable", sortable: false},
      {text: "Tax id", sortable: false},
      {text: "Balance", sortable: false},
      {text: "Transfers", sortable: false},
      {text: "Payouts", sortable: false},
      {text: "", sortable: false},
    ],
    selectedItem: null,
    showMarkInactiveDialog: false,
    showMarkActiveDialog: false,
    confirmText: null,
    actionLoading: false
  }),
  watch: {
    showMarkInactiveDialog(newValue) {
      if(! newValue) {
        this.resetDialogs();
      }
    },
    showMarkActiveDialog(newValue) {
      if(! newValue) {
        this.resetDialogs();
      }
    }
  },
  methods: {
    ...mapActions('core', ['updatePayoutAccount']),
    resetDialogs() {
      this.selectedItem = null;
      this.confirmText = null;
      this.actionLoading = false;
      this.showMarkInactiveDialog = false;
      this.showMarkActiveDialog = false;
    },
    markInactive() {
      this.actionLoading = true;

      let params = {
        id: this.selectedItem.uuid,
        active: false
      };

      this.updatePayoutAccount(params).then(() => {
        this.setSnackSuccess("Saved");
        this.selectedItem.active = false;
        this.resetDialogs();
      }, () => {
        this.setSnackError("Failed");
        this.actionLoading = false;
      });
    },
    markActive() {
      this.actionLoading = true;

      let params = {
        id: this.selectedItem.uuid,
        active: true
      };

      this.updatePayoutAccount(params).then(() => {
        this.setSnackSuccess("Saved");

        // Mark all as inactive
        this.items.forEach(payoutAccount => {
          payoutAccount.active = false;
        });

        // Mark current selected as active
        this.selectedItem.active = true;

        this.resetDialogs();
      }, () => {
        this.setSnackError("Failed");
        this.actionLoading = false;
      });
    }
  }
};
</script>
